<style lang="scss" scoped>
.page-vehicles-lowbattery-statistics {
    .small-input {
        width: 136px;
    }
}
</style>

<template>
    <div class="page-vehicles-lowbattery-statistics">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-tooltip content="如果发现数据不正确，请重新生成后再查看!" placement="top-start">
                        <el-button class="mrgb5" type="danger" size="medium" @click="_resetData()">重新生成数据</el-button>
                    </el-tooltip>
                </div>
                <div class="fr">
                    <span class="mrgr5 mrgb5">
                        <span class="col_warning">丢单次数大于等于：</span>
                        <el-tooltip content="选择后将显示大于等于当前次数的低电丢单数据" placement="top-start">
                            <el-select class="c-el-input small-input" placeholder="丢单次数" size="medium" filterable
                                v-model="listQuery.minLostCount" @change="_search()">
                                <el-option v-for="t in lostCounts" :key="t.lost" :label="t.lost" :value="t.lost">
                                    <span class="fl">{{ t.lost }}</span>
                                </el-option>
                            </el-select>
                        </el-tooltip>
                    </span>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" :clearable="false"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="getLostCounts()"
                        align="right">
                    </el-date-picker>
                    <el-button class="mrgb5" type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel()" v-if="true"><i class="fa fa-sign-out"></i> 导出低电丢单数据</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" :span-method="objectSpanMethod" style="width: 100%" border size="small" @cell-dblclick="_query">
                <el-table-column label="日期(星期)" prop="date" width="120">
                    <template slot-scope="scope">
                        {{ scope.row.date }}<br />
                        <el-tag type="parmary" size="mini" v-if="scope.row.week">{{ scope.row.week }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="总订单数" prop="orderCount"></el-table-column>
                <el-table-column label="低电丢单(总数)" prop="lostCount"></el-table-column>
                <el-table-column label="总占比(标准1%)" prop="lostRate"></el-table-column>
                <el-table-column label="爆单车辆(辆)" prop="carId">
                    <template slot-scope="scope">
                        <el-link @click.native="_query(scope.row)" v-if="scope.row.carId != '--'">{{ scope.row.carId }}</el-link>
                        <span v-else>{{ scope.row.carId }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="爆单丢单次数" prop="itemLostCount"></el-table-column>
                <el-table-column label="爆单丢单占比(%)" prop="itemLostRate"></el-table-column>
                <el-table-column label="爆单时间" prop="itemTime"></el-table-column>
                <el-table-column label="爆单点位" prop="itemLocation" width="300"></el-table-column>
            </el-table>
        </div>

        <!--车辆日志-->
        <dialog-logs :ref="refDialogLogs"></dialog-logs>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from "moment"
import DialogLogs from "./components/dialogLogs"
import { LostOrderReports } from "@/mixins/commonPageExport"
export default {
    name: "pageVehiclesLowBatteryStatistics",
    components: { DialogLogs },
    data() {
        let that = this
        return {
            refDialogLogs: "refLowBatteryStatisticsToDialogLogs",
            tableData: [],
            listQuery: {
                type: 1,
                area: "",
                beginDate: "",
                endDate: "",
                minLostCount: 10
            },
            selectedTime: [moment().format("YYYY-MM-01 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")],
            lostCounts: [],
            pickerOptions: {
                shortcuts: [
                    /* {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    }, */
                    {
                        text: "本周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks", true)
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "本月",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "months", true)
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "上月",
                        onClick: (picker) => {
                            let startDate = moment().subtract(1, "months")
                            let timeArr = [startDate.format("YYYY-MM-01 00:00:00"), startDate.endOf("month").format("YYYY-MM-DD 23:59:59")]
                            picker.$emit('pick', timeArr)
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    }
                    /* ,
                    {
                        text: "近两月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 2, "months")
                            that.setQueryTime(timeArr)
                        }
                    } */
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getLostCounts()
            })
        }
    },
    mounted() {
        this.getLostCounts()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = null
            if (isStartOf) {
                startTime = moment().startOf(dateStr).format("YYYY-MM-DD 00:00:00")
                endTime = moment().format("YYYY-MM-DD 23:59:59")
            } else {
                startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
                endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            }
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        getDataList() {
            this.setSelectedTime()
            if (!this.listQuery.beginDate || !this.listQuery.endDate) {
                this.warningMsg("请选择查询时间段!")
                return false
            }
            let daysDiff = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "days")
            if (daysDiff > 30) {
                this.warningMsg("查询时间不能超过31天!")
                return false
            }
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funReport.GetLostDayStatistics(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.tableData = []
                if (response && response.length > 0) {
                    let tableData = response.map((x, i) => {
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        return x
                    })
                    for (let i in tableData) {
                        let item = tableData[i]
                        if (item.items.length > 0) {
                            for (let k in item.items) {
                                let x = item.items[k]
                                let date = "", week = "", orderCount = "", lostCount = "", lostRate = ""
                                if (k < 1) {
                                    date = moment(item.date).format("YYYY-MM-DD")
                                    week = this.getFormatWeek(item.date)
                                    orderCount = item.orderCount || 0
                                    lostCount = item.lostCount || 0
                                    lostRate = `${window.$util.operationMulti(item.lostRate, 100)}%`
                                }
                                let temp = {
                                    date,
                                    week,
                                    oldDate: item.date || "",
                                    orderCount,
                                    lostCount,
                                    lostRate,
                                    carId: x.carId,
                                    itemLostCount: x.lostCount || 0,
                                    itemLostRate: `${window.$util.operationMulti(x.lostRate, 100)}%`,
                                    itemTime: `${moment(x.startTime).format("HH:mm")}——${moment(x.endTime).format("HH:mm")}`,
                                    itemLocation: x.location || ""
                                }
                                this.tableData.push(temp)
                            }
                        } else {
                            let temp = {
                                date: moment(item.date).format("YYYY-MM-DD"),
                                week: this.getFormatWeek(item.date),
                                orderCount: item.orderCount || 0,
                                lostCount: item.lostCount || 0,
                                lostRate: `${window.$util.operationMulti(item.lostRate, 100)}%`,
                                carId: "--",
                                itemLostCount: "--",
                                itemLostRate: "--",
                                itemTime: "--",
                                itemLocation: "--"
                            }
                            this.tableData.push(temp)
                        }
                    }
                }
            })
        },
        async getLostCounts() {
            this.setSelectedTime()
            let params = {
                area: this.$store.getters.serviceArea || "",
                beginDate: this.listQuery.beginDate,
                endDate: this.listQuery.endDate,
            }
            await funReport.GetLowPowerStatistics(params).then(res => {
                this.lostCounts = res
            })
            this._search()
        },
        _resetData() {
            this.confirm("确定要重置【<span class='col_danger'>低电丢单统计</span>】数据吗？", "温馨提示", {
                dangerouslyUseHTMLString: true
            }).then(() => {
                window.$common.fullLoading()
                funReport.UpdateLostDayStatistics(this.listQuery).then(() => {
                    window.$common.closeFullLoading()
                    this.getLostCounts()
                })
            })
        },
        _query(row) {
            let temp = { ...row }
            temp.vehicleId = temp.carId
            temp.date = temp.date || temp.oldDate || ""
            this.$refs[this.refDialogLogs].open({ logsItem: temp })
            // this.$router.push({ name: "pageVehiclesLogs", params: { logsItem: row } })
        },
        _search() {
            this.getDataList()
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            /* if (columnIndex === 0) {
                if (rowIndex % 2 === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                }
            } */
        },
        openExportExcel() {
            let searchs = JSON.parse(JSON.stringify(this.listQuery))
            let tableDatas = JSON.parse(JSON.stringify(this.tableData))
            let exportData = ""
            if (tableDatas.length <= 0) {
                this.warningMsg("没有可导出的数据!")
                return false
            }
            if (searchs.beginDate && searchs.endDate) {
                let sbtd = moment(searchs.beginDate).format("YYYY-MM-DD")
                let ebtd = moment(searchs.endDate).format("YYYY-MM-DD")
                exportData = sbtd == ebtd ? sbtd : `${sbtd}至${ebtd}`
            } else {
                exportData = "全部"
            }
            let exportName = `${this.$store.getters.serviceArea}低电丢单数据(${exportData}).xlsx`
            console.log(tableDatas)
            LostOrderReports(exportName, tableDatas, "低电丢单")
        }
    }
}
</script>